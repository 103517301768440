<template>
    <div id="footerCredits">
        <p class="copyright" v-html="copyright"></p>
        <p style="display:inline">
            {{credits}}
        </p>
        <a href="https://uptowncreative.io/" target="_blank">Uptown Creative</a>
    </div>
</template>

<script>
export default {
    name: "FooterCredits",
    data(){
        var d = new Date();
        return{
            copyright: "Copyright &copy " + d.getFullYear() + " Coastal Luxe Living. Cal BRE# 01859461 All Rights Reserved.<br>11677 San Vicente Blve #307, Los Angeles CA 90049. Information deemed reliable not guaranteed or verified.",
            credits: "Website crafted by",
        }
    },
}
</script>

<style lang="scss" scoped>
#footerCredits{
    padding: 2rem 0 2rem 0;
    text-align: center;
    p {
        font-weight: 500;
        margin: 0;
        padding: 0.25rem 0;
        font-size: 13px;
    }
    a{
        color: #000;
        font-size: 13px;
        font-weight: 500;
        text-decoration: underline;
        &:hover{
            opacity: 0.5;
        }
    }
    .copyright{
        font-size: 10px;
        opacity: 0.4;
        color: #26407c;
    }
}
@media (max-width: $mobile) { 
    #footerCredits{
        padding: 2rem;
    }
}
</style>